import { lazy } from 'react';
import { RouteObject } from 'react-router-dom';

import { links } from '@/common/utils/links';

const Dashboard = lazy(() => import('./pages/dashboard'));
const Dashboard2 = lazy(() => import('./pages/dashboard-2'));

export const DashboardRoutes: RouteObject[] = [
  {
    index: true,
    element: <Dashboard />,
  },
  {
    path: links.nexpro.dashboard,
    element: <Dashboard2 />,
  },
];
