import { Outlet } from 'react-router-dom';

import * as style from './styles';

export function MapLayout() {
  return (
    <div className={style.container()}>
      <Outlet />
    </div>
  );
}
