import { lazy } from 'react';
import { RouteObject } from 'react-router-dom';

import { links } from '@/common/utils/links';

const Travel = lazy(() => import('./pages/travel'));

export const TravelRoutes: RouteObject[] = [
  {
    path: links.nexpro.travel,
    element: <Travel />,
  },
];
