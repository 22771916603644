import axios from 'axios';
import { toast } from 'react-toastify';

import { removeItemLocal } from '@/common/utils/localStorage';

import { ENV } from './env';

export const geofenceHttpClient = axios.create({
  baseURL: ENV.VITE_GEOFENCE_API_URL,
  headers: {
    'Content-Type': 'application/json',
  },
});

geofenceHttpClient.interceptors.response.use(
  response => ({
    ...response,
  }),
  async error => {
    if (error.response.status === 401) {
      toast.error('Sua sessão expirou, faça login novamente', {
        containerId: 'secondary',
        autoClose: 3000,
        toastId: 'session-expired',
      });

      setTimeout(() => {
        removeItemLocal('app');
        window.location.href = '/';
      }, 3500);
    }

    throw error;
  },
);
