import { useTranslation } from 'react-i18next';

import { links } from '../utils/links';

export function useUserProfileMenus() {
  const { t } = useTranslation('userProfile/menus');
  return [
    {
      key: 'profile',
      title: t('myProfile'),
      link: links.userProfile.profile,
      flag: 'settings_sidebar_profile',
    },
    {
      key: 'password',
      title: t('password'),
      link: links.userProfile.password,
      flag: 'settings_sidebar_password',
    },
  ];
}
