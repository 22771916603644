import { IPagination } from '@/common/types/Pagination';
import { geofenceHttpClient } from '@/config/geofenceHttpClient';

export enum UserTypeEnum {
  ACCOUNT = 'ACCOUNT',
  COMPANY = 'COMPANY',
}

export enum GeofenceBehaviorEnum {
  ENTRADA = 'ENTRADA',
  SAIDA = 'SAIDA',
  ENTRADA_SAIDA = 'ENTRADA_SAIDA',
  PARADO_TEMPO = 'PARADO_TEMPO',
  VELOCIDADE_RESTRINGIDA = 'VELOCIDADE_RESTRINGIDA',
}

export enum GeofenceBehaviorTypeEnum {
  TRANSPOSICAO = 'TRANSPOSICAO',
  VELOCIDADE = 'VELOCIDADE',
  TEMPO = 'TEMPO',
}

export enum GeofenceAreaTypeEnum {
  ENTRADA_NA_PLANTA = 'ENTRADA_NA_PLANTA',
  AREA_DE_DESCANSO_SEGURA = 'AREA_DE_DESCANSO_SEGURA',
  CAMINHO_SINUOSO = 'CAMINHO_SINUOSO',
  POSTO_DE_COMBUSTIVEL = 'POSTO_DE_COMBUSTIVEL',
  CIDADE = 'CIDADE',
  CLIENTE = 'CLIENTE',
  CONTROLE_SANITARIO = 'CONTROLE_SANITARIO',
  POSTO_DE_CONTROLE_VEICULAR = 'POSTO_DE_CONTROLE_VEICULAR',
  CRUZAMENTO_DE_LINHA_FERROVIARIA = 'CRUZAMENTO_DE_LINHA_FERROVIARIA',
  CRUZAMENTO_PERIGOSO = 'CRUZAMENTO_PERIGOSO',
  CURVA_PERIGOSA = 'CURVA_PERIGOSA',
  PARAGEM_NAO_AUTORIZADA = 'PARAGEM_NAO_AUTORIZADA',
  PARADA_INSEGURA = 'PARADA_INSEGURA',
  CRUZAMENTO = 'CRUZAMENTO',
  PORTO = 'PORTO',
  ROTATORIA_PERIGOSA = 'ROTATORIA_PERIGOSA',
  ROTA = 'ROTA',
  ROTA_DETERIORADA = 'ROTA_DETERIORADA',
  TRANSPORTE = 'TRANSPORTE',
  PATIO = 'PATIO',
  AREA_DE_TRABALHO = 'AREA_DE_TRABALHO',
  AREA_DE_INCLINACAO = 'AREA_DE_INCLINACAO',
  ZONA_DE_CASCALHO = 'ZONA_DE_CASCALHO',
  AREA_DENSAMENTE_POPULADA = 'AREA_DENSAMENTE_POPULADA',
  ZONA_URBANA = 'ZONA_URBANA',
}

export enum WeekDaysEnum {
  monday = 'SEGUNDA',
  tuesday = 'TERCA',
  wednesday = 'QUARTA',
  thursday = 'QUINTA',
  friday = 'SEXTA',
  saturday = 'SABADO',
  sunday = 'DOMINGO',
}

export interface ICreateGeofence {
  title: string;
  polygon: [];
  companyDocumentNumber: string;
  companyDisplayName: string;
  geofenceBehavior: string;
  geofenceBehaviorType: string;
  geofenceAreaType: string;
  maximumLimitBehavior: number;
  ownerName: string;
  ownerEmail: string;
  isActive: boolean;
  createdAt: string;
  updatedAt: string;
  geofenceTriggeredEmails: [];
  geofenceTriggeredDays: [];
  geofenceTriggeredChassis: [];
}

export interface IGeofenceTriggeredDays {
  weekDay: string;
  initialHour: string;
  finalHour: string;
}

export interface IGeofencePayload {
  id: string;
  title: string;
  createdAt: string;
  companyDocumentNumber: string;
  companyDisplayName?: string;
  companyId: string;
  geofenceBehavior: GeofenceBehaviorEnum;
  geofenceBehaviorType: GeofenceBehaviorTypeEnum;
  geofenceAreaType: GeofenceAreaTypeEnum;
  maximumLimitBehavior: number;
  polygon: any;
  updatedAt: string;
  ownerName: string;
  ownerEmail: string;
  GeofenceTriggeredDays: IGeofenceTriggeredDaysPayload[];
  GeofenceTriggeredEmails: IGeofenceTriggeredEmailsPayload[];
  GeofenceVehicle: IGeofenceVehiclePayload[];
  isActive: boolean;
}

export interface IGeofenceTriggeredDaysPayload {
  id: string;
  weekDay: string;
  initialHour: string;
  finalHour: string;
}

export interface IGeofenceTriggeredEmailsPayload {
  id: string;
  email: string;
}

export interface IGeofenceVehiclePayload {
  id: string;
  chassis: string;
  licensePlate?: string;
  vehicleModel?: string;
  vehicleType?: string;
}

export async function createGeofence(data: any) {
  return geofenceHttpClient.post('/geofence', data);
}

export async function removeGeofence(id: string) {
  return geofenceHttpClient.delete(`/geofence/${id}`);
}

export async function paginateGeofence(
  page = 1,
  order?: string,
  freeSearch?: string | null,
  companyDocumentNumber?: string | null,
  geofenceBehavior?: string | null,
  geofenceAreaType?: string | null,
  isActive?: boolean | null,
) {
  const orderBy: Record<string, string> = {};
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const where: Record<string, any> = { isActive };

  if (order) orderBy[order] = 'asc';

  if (freeSearch) {
    Object.assign(where, { title: freeSearch });
  }

  if (companyDocumentNumber) {
    Object.assign(where, {
      companyDocumentNumber,
    });
  }

  if (geofenceBehavior) {
    Object.assign(where, {
      geofenceBehavior,
    });
  }

  if (geofenceAreaType) {
    Object.assign(where, {
      geofenceAreaType,
    });
  }

  return geofenceHttpClient.get<IPagination<any>>('/geofence/paginate', {
    params: {
      limit: 25,
      page,
      relations: {
        orderBy,
        where,
      },
    },
  });
}

export async function editGeofence({ id, ...updateDto }: any) {
  return geofenceHttpClient.put(`/geofence/${id}`, updateDto.payload);
}

export async function searchCompany(value: string) {
  return geofenceHttpClient.get<any[]>('/company/findAll', {
    params: {
      relations: {
        where: {
          OR: [
            { documentNumber: { contains: value } },
            { displayName: { contains: value } },
            { companyName: { contains: value } },
          ],
        },
      },
    },
  });
}

export async function findGeofenceById(id: string) {
  return geofenceHttpClient.get<IGeofencePayload>(`/geofence/findOne/${id}`, {
    params: {
      include: {
        GeofenceTriggeredDays: true,
        GeofenceTriggeredEmails: true,
        GeofenceVehicle: true,
      },
    },
  });
}
