import { lazy } from 'react';
import { RouteObject } from 'react-router-dom';

import { links } from '@/common/utils/links';

const ControlRoom = lazy(() => import('./pages/ControlRoom'));

export const ControlRoomRoutes: RouteObject[] = [
  {
    path: links.nexpro.controlRoom,
    element: <ControlRoom />,
  },
];
