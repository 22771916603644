import Cookies from 'js-cookie';
import { create } from 'zustand';

import { LoginApp } from '@/apps/login';
import { NexproApp } from '@/apps/nexpro';
import { NexproAppNavigation } from '@/apps/nexpro/config/nexproAppNavigation';

import { IAppNavigation } from '../types/AppNavigation';
import { getItemLocal } from '../utils/localStorage';

export enum EnumAPPS {
  LOGIN = 'LOGIN',
  API_TELEMETRY = 'API_TELEMETRY',
  NEXPRO = 'APP_NEXPRO',
}

interface IAppStore {
  app: EnumAPPS;
  appNavigation: IAppNavigation[];
  changeApp: (app: EnumAPPS) => void;
  matchApp: (app: EnumAPPS) => typeof NexproApp;
}

const store = create<IAppStore>(set => {
  const loadedApp = getItemLocal<EnumAPPS>('app', { parse: false });
  const accessToken = Cookies.get('accessToken');

  const matchAppNavigation = (app: EnumAPPS): IAppNavigation[] => {
    switch (app) {
      case EnumAPPS.NEXPRO:
        return NexproAppNavigation();

      default:
        return [];
    }
  };

  return {
    app: accessToken && loadedApp ? loadedApp : EnumAPPS.LOGIN,
    appNavigation: matchAppNavigation(loadedApp || EnumAPPS.API_TELEMETRY),

    matchApp(app: EnumAPPS) {
      switch (app) {
        case EnumAPPS.NEXPRO:
          return NexproApp;

        default:
          return LoginApp;
      }
    },
    changeApp(newApp: EnumAPPS) {
      set(() => ({
        app: newApp,
        appNavigation: matchAppNavigation(newApp),
      }));
    },
  };
});

export const useAppStore = () => {
  return store();
};
