import { lazy } from 'react';
import { RouteObject } from 'react-router-dom';

import { links } from '@/common/utils/links';
import { UserProfileLayout } from '@/layouts/UserProfile';

const Profile = lazy(() => import('../pages/profile'));
const Password = lazy(() => import('../pages/password'));
const UserProfileOptions = lazy(() => import('../pages/userProfileOptions'));

export const UserProfileRoutes: RouteObject[] = [
  {
    element: <UserProfileLayout />,
    children: [
      {
        path: links.userProfile.profile,
        element: <Profile />,
      },
      {
        path: links.userProfile.password,
        element: <Password />,
      },
      {
        path: links.userProfile.userProfileOptions,
        element: <UserProfileOptions />,
      },
    ],
  },
];
