import { lazy } from 'react';
import { RouteObject } from 'react-router-dom';

import { links } from '@/common/utils/links';

const Drivers = lazy(() => import('./pages/drivers'));
const Vehicles = lazy(() => import('./pages/vehicles'));
const Groups = lazy(() => import('./pages/groups'));
const Maintenance = lazy(() => import('./pages/maintenance'));

export const RegistrationsRoutes: RouteObject[] = [
  {
    path: links.nexpro.registrations.drivers,
    element: <Drivers />,
  },
  {
    path: links.nexpro.registrations.vehicles,
    element: <Vehicles />,
  },
  {
    path: links.nexpro.registrations.groups,
    element: <Groups />,
  },
  {
    path: links.nexpro.registrations.maintenance,
    element: <Maintenance />,
  },
];
