import { httpClient } from '@/config/httpClient';

export async function activateUserAccount(token: string) {
  return httpClient.post('/user/activate-user-account', null, {
    headers: {
      'verify-token': token,
    },
  });
}

export async function acceptTerms() {
  return httpClient.post('/user/accept-term');
}
