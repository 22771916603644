import { RefreshCcw } from 'lucide-react';
import { useTranslation } from 'react-i18next';

import Button from '../Button';

import * as style from './styles';

export default function UnknownErrorComponent() {
  const { t } = useTranslation('common');

  const handleForceReloadPage = () => {
    window.location.reload();
  };

  return (
    <div className={style.container()}>
      <div className={style.content()}>
        <main className={style.main()}>
          <span className={style.status()}>
            {t('defaultErrorTitle')}
            <br />
            {t('defaultErrorName')} 500
          </span>

          <div className={style.textWrapper()}>
            <p className={style.textError()}>
              {`${t('unknownErrorOccurred')}.`}
            </p>
            <p className={style.tryAgainText()}>{`${t('tryAgainText')}`}</p>
          </div>
          <div className={style.buttons()}>
            <div className={style.wrapperButton()}>
              <Button
                text={t('reloadPageButton')}
                leadingIcon={
                  <RefreshCcw size={20} className={style.homeIcon()} />
                }
                onClick={handleForceReloadPage}
              />
            </div>
          </div>
        </main>
      </div>
    </div>
  );
}
