import { IGoogleRecaptchaInstance } from '@/@types/GoogleRecaptcha';
import { zodResolver } from '@hookform/resolvers/zod';
import flagsmith from 'flagsmith';
import { useFlags } from 'flagsmith/react';
import { Eye, EyeOff, Lock } from 'lucide-react';
import { useLayoutEffect, useRef, useState } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';

import NexproLogoImg from '@/assets/icons/nexpro2.jpg';
import RecaptchaIcon from '@/assets/icons/recaptcha.svg';
import Button from '@/common/components/Button';
import { Checkbox } from '@/common/components/Checkbox';
import { TextInput } from '@/common/components/TextInput';
import { EnumAPPS, useAppStore } from '@/common/hooks/AppStore';
import { useCheckModuleIsAnAPP } from '@/common/hooks/useCheckModuleIsAnAPP';
import { useUserStore } from '@/common/hooks/UserStore';
import { useToast } from '@/common/hooks/useToast';
import { getUserInfo } from '@/common/services/user';
import { IAxiosError } from '@/common/types/AxiosError';
import {
  IGetUserInfo,
  IUserInfoUserAccount,
  IUserInfoUserCompany,
} from '@/common/types/User';
import { links } from '@/common/utils/links';
import { setItemLocal } from '@/common/utils/localStorage';
import { ENV } from '@/config/env';

import { SignInForm, useLogin } from '../../hooks/useLogin';

import { AcceptTermsOfUse } from './components/AcceptTermsOfUse';
import * as style from './styles';

export default function SignIn() {
  const [loadingCaptchaToken, setLoadingCaptchaToken] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const { toast } = useToast();
  const isAllowSignIn = useFlags(['allow_sign_in']);

  const { t } = useTranslation(['common', 'login/signIn']);
  const { SignInSchema, signIn, isLoadingSignIn } = useLogin();
  const navigate = useNavigate();
  const { storeAccountUser, storeCompanyUser } = useUserStore();

  const { changeApp } = useAppStore();

  const hookForm = useForm<SignInForm>({
    mode: 'onBlur',
    resolver: zodResolver(SignInSchema),
    defaultValues: {
      rememberData: true,
    },
  });

  const captchaRef = useRef<IGoogleRecaptchaInstance>(null);

  const { check } = useCheckModuleIsAnAPP();

  const verifyCaptcha = async () => {
    try {
      if (!captchaRef?.current) {
        toast.warn(t('login/signIn:reCaptchaHumanError'));
        return null;
      }

      setLoadingCaptchaToken(true);
      captchaRef?.current?.reset();

      const reCaptchaToken = await captchaRef?.current?.executeAsync();

      return reCaptchaToken;
    } catch (error) {
      toast.error(t('login/signIn:reCaptchaAPIError'));
      return null;
    } finally {
      setLoadingCaptchaToken(false);
    }
  };

  const loadUserCompanyApp = async (
    user: IGetUserInfo,
    company: IUserInfoUserCompany,
  ) => {
    await flagsmith.setTraits({
      userType: user.userType,
      role: company.role,
    });
    const driver =
      user?.Driver && user?.Driver?.length > 0 ? user?.Driver[0] : null;

    storeCompanyUser({ user, company, driver });
    changeApp(EnumAPPS.NEXPRO);
    setItemLocal('app', EnumAPPS.NEXPRO!);
    setItemLocal('companyId', company.companyId);
  };

  const loadUserAccountApp = async (
    user: IGetUserInfo,
    account: IUserInfoUserAccount,
  ) => {
    await flagsmith.setTraits({
      userType: user.userType,
      role: account.role,
    });

    storeAccountUser({ user, account });
    changeApp(EnumAPPS.NEXPRO);
    setItemLocal('app', EnumAPPS.NEXPRO!);
  };

  const requestSignIn = async (data: SignInForm, reCaptchaToken: string) => {
    try {
      await signIn({ ...data, reCaptchaToken });
      const response = await getUserInfo();

      if (!response.data.isTermAccepted) {
        toast.warn(t('login/signIn:termsOfUseNotAcceptedYet'));
        window.HSOverlay.open(
          document.getElementById('hs-modal-terms-of-use')!,
        );
        return;
      }

      const user = response.data;

      flagsmith.identify(user?.email);
      flagsmith.getFlags();
      flagsmith.startListening(1000 * 60 * 15);

      if (!user) {
        toast.error(t('login/signIn:signInError'));
        return;
      }

      const isUserCompany = Boolean(
        user.userType === 'COMPANY' && user.UserCompany,
      );

      const isUserAccount = Boolean(
        user.userType === 'ACCOUNT' && user.UserAccount,
      );

      const { preferredCompanyId } = user.UserPreference.length
        ? user.UserPreference[0]
        : { preferredCompanyId: null };

      if (isUserCompany) {
        if (user?.UserCompany && user?.UserCompany?.length <= 0) {
          toast.error(t('login/signIn:loadNexproModuleError'));
          return;
        }

        const companiesWithFrontEndModules =
          user?.UserCompany?.filter(
            userCompany =>
              userCompany.Company.CompanyModule.filter(companyModule =>
                check(companyModule.moduleKey as EnumAPPS),
              ).length,
          ) || [];

        if (companiesWithFrontEndModules.length === 1) {
          loadUserCompanyApp(user, companiesWithFrontEndModules[0]);
          return;
        }

        if (!companiesWithFrontEndModules.length) {
          toast.error(t('login/signIn:loadNexproModuleError'));
          return;
        }

        const preferredCompany = user?.UserCompany?.find(
          userCompany => userCompany.companyId === preferredCompanyId,
        );

        if (preferredCompany && preferredCompany.Company.CompanyModule.length) {
          const atLeastOneFrontEndApp =
            preferredCompany.Company.CompanyModule.some(companyModule =>
              check(companyModule.moduleKey as EnumAPPS),
            );

          if (!atLeastOneFrontEndApp) {
            toast.error(t('login/signIn:loadNexproModuleError'));
            return;
          }

          await loadUserCompanyApp(user, preferredCompany);
          return;
        }

        if (user?.UserCompany?.length === 1) {
          const uniqueCompany = user?.UserCompany[0];

          if (uniqueCompany.Company.CompanyModule.length <= 0) {
            toast.error(t('login/signIn:loadNexproModuleError'));
            return;
          }

          const atLeastOneFrontEndApp =
            uniqueCompany.Company.CompanyModule.some(companyModule =>
              check(companyModule.moduleKey as EnumAPPS),
            );

          if (!atLeastOneFrontEndApp) {
            toast.error(t('login/signIn:loadNexproModuleError'));

            return;
          }

          await loadUserCompanyApp(user, uniqueCompany);
          return;
        }

        navigate(links.login.appSelection, {
          state: {
            user,
          },
        });
        return;
      }

      if (isUserAccount && user?.UserAccount?.length) {
        const account = user?.UserAccount[0];
        storeAccountUser({ user, account });

        await loadUserAccountApp(user, account);
        return;
      }

      toast.error(t('login/signIn:loadNexproModuleError'));
    } catch (error: unknown) {
      const requestError = error as IAxiosError;

      toast.error(
        requestError?.response?.data?.friendlyMessage ||
          t('login/signIn:signInError'),
      );
    }
  };

  const handleSignIn = async (data: SignInForm) => {
    const reCaptchaToken = await verifyCaptcha();

    if (!reCaptchaToken) {
      hookForm.setError('reCaptcha', {
        message: 'reCaptcha error',
      });
      return;
    }

    await requestSignIn(data, reCaptchaToken);
  };

  const handleToggleShowPassword = () => setShowPassword(!showPassword);

  useLayoutEffect(() => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    if (!window?.recaptchaOptions) return;

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    Object.assign(window.recaptchaOptions, {
      nonce: document
        ?.querySelector("meta[name='csp-nonce']")
        ?.getAttribute('content'),
    });
  }, []);

  return (
    <>
      <main className={style.main()}>
        <header className={style.header()}>
          <img src={NexproLogoImg} alt="IVECO" className={style.logo()} />
        </header>

        <FormProvider {...hookForm}>
          <form
            className={style.form()}
            onSubmit={hookForm.handleSubmit(handleSignIn)}
          >
            <TextInput
              size="lg"
              name="username"
              placeholder={t('login/signIn:username')}
            />

            <TextInput
              size="lg"
              name="password"
              type={showPassword ? 'text' : 'password'}
              placeholder={t('login/signIn:password')}
              TrailingIcon={
                showPassword ? (
                  <Eye className="text-neutral-500" size={16} />
                ) : (
                  <EyeOff className="text-neutral-500" size={16} />
                )
              }
              handleClickTrailingIcon={handleToggleShowPassword}
            />

            <fieldset className={style.options()}>
              <Checkbox
                name="rememberData"
                label={t('login/signIn:rememberMe')}
                extraClassNames={style.checkbox()}
              />

              <div className={style.divider()} />

              <div className={style.wrapperLock()}>
                <Lock size={14} className={style.lock()} strokeWidth={3} />

                <Link to="/forgot-password" className={style.forgotPassword()}>
                  {t('login/signIn:forgotPassword')}
                </Link>
              </div>
            </fieldset>

            <fieldset className={style.reCaptchaContainer()}>
              <div
                className={style.reCaptcha({
                  hasError: !!hookForm.formState.errors.reCaptcha,
                })}
              >
                <Checkbox name="reCaptcha" label="Não sou um robô" />

                <img src={RecaptchaIcon} alt="reCAPTCHA" />
              </div>

              {hookForm.formState.errors.reCaptcha && (
                <p className={style.reCaptchaError()}>
                  {t('login/signIn:reCaptchaError')}.
                </p>
              )}
            </fieldset>

            <Button
              type="submit"
              isLoading={isLoadingSignIn || loadingCaptchaToken}
              text={t('login/signIn:signIn')}
              disabled={!isAllowSignIn.allow_sign_in.enabled}
            />
          </form>
        </FormProvider>

        <footer className={style.footer()}>
          <a
            href={ENV.VITE_TERMS_OF_USE_URL}
            target="_bank"
            className={style.privacyPolicy()}
          >
            {t('common:termsOfUseAndPrivacyPolicy')}
          </a>

          <p className={style.copyright()}>
            &copy; Copyright By IVECO - {t('login/signIn:allRightsReserved')}
          </p>
        </footer>
      </main>

      <ReCAPTCHA
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        ref={captchaRef}
        sitekey={ENV.VITE_GOOGLE_RECAPTCHA_SITE_KEY}
        size="invisible"
        className="hidden"
      />

      <AcceptTermsOfUse
        retrySignIn={() => handleSignIn(hookForm.getValues())}
      />
    </>
  );
}
