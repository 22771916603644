import createCache from '@emotion/cache';
import { CacheProvider } from '@emotion/react';
import { QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import flagsmith from 'flagsmith';
import { FlagsmithProvider } from 'flagsmith/react';
import L from 'leaflet';
import icon from 'leaflet/dist/images/marker-icon.png';
import iconShadow from 'leaflet/dist/images/marker-shadow.png';
import { I18nextProvider } from 'react-i18next';
import { ToastContainer as DefaultToastContainer } from 'react-toastify';

import { ToastContainer } from './common/components/Toast';
import { ENV } from './config/env';
import i18n from './config/i18n';
import { queryClient } from './config/queryClient';
import { AppRoutes } from './routes/index.routes';

const cache = createCache({
  key: 'emotion-cache-key',
  nonce: 'react-select',
});

const DefaultIcon = L.icon({
  iconUrl: icon,
  shadowUrl: iconShadow,
  iconAnchor: [12, 41],
});

L.Marker.prototype.options.icon = DefaultIcon;

function App() {
  return (
    <CacheProvider value={cache}>
      <I18nextProvider i18n={i18n}>
        <FlagsmithProvider
          options={{
            environmentID: ENV.VITE_FLAGSMITH_ENV_ID,
            cacheFlags: true,
          }}
          flagsmith={flagsmith}
        >
          <QueryClientProvider client={queryClient}>
            <AppRoutes />
            <ToastContainer />
            <DefaultToastContainer
              containerId="secondary"
              position="bottom-right"
              enableMultiContainer
            />
            <ReactQueryDevtools initialIsOpen={false} position="bottom-right" />
          </QueryClientProvider>
        </FlagsmithProvider>
      </I18nextProvider>
    </CacheProvider>
  );
}

export default App;
