import { Suspense } from 'react';
import { Outlet } from 'react-router-dom';

import { LoadingFallback } from '@/common/components/LoadingFallback';

import { AppSelectionModal } from './components/AppSelectionModal';
import { Sidebar } from './components/Sidebar';
import { container, content } from './styles';

export function Root() {
  return (
    <div className={container()}>
      <Sidebar />

      <div className={content()}>
        <Suspense fallback={<LoadingFallback />}>
          <Outlet />
        </Suspense>
      </div>

      <AppSelectionModal />
    </div>
  );
}
