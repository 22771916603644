import { cva } from 'class-variance-authority';

export const container = cva(
  [
    'border-r',
    'bg-white',
    'border-neutral-200',
    'py-4',
    'px-4',
    'shadow-2xl',
    'flex',
    'flex-col',
    'w-full',
    'md:max-w-[14rem]',
    'fixed',
    'top-0',
    'z-[49]',
    'md:py-8',
    'md:relative',
    'bottom-0',
    'data-[state=closed]:bottom-auto',
    'overflow-y-auto',
    'overflow-x-hidden',
    'md:overflow-y-visible',
    'md:overflow-x-visible',
    'max-h-screen',
    'group',
  ],
  {
    variants: {
      containerBottom: {
        'bottom-0': '!bottom-0',
        'bottom-auto': '!bottom-auto',
      },
    },
  },
);

export const header = cva([
  'flex',
  'w-full',
  'justify-between',
  'md:justify-center',
  'items-center',
  'gap-4',
]);

export const tinyLogo = cva([
  'max-h-[24px]',
  'md:max-h-[42px]',
  'max-w-[224px]',
  'mr-auto',
]);

export const logo = cva([
  'max-h-[24px]',
  'md:max-h-[32px]',
  'md:min-w-[180px]',
  'md:max-w-[180px]',
  'mr-auto',
]);

export const collapseButton = cva([
  'hidden',
  'md:group-hover:block',
  'group-data-[collapsed=true]:block',
  'bg-brand-50',
  'border',
  'border-brand-100',
  'w-fit',
  'p-1',
  'rounded-full',
  'shadow-md',
  'absolute',
  'top-4',
  '-right-3',
  'z-50',
  'hover:bg-brand-100',
]);

export const menu = cva([
  'space-y-2',
  'hs-accordion-group',
  'overflow-y-auto',
  'h-[20rem]',
  'md:h-full',
  'md:max-h-menu',
]);
