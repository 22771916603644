import { lazy } from 'react';
import { RouteObject } from 'react-router-dom';

import { links } from '@/common/utils/links';

const Monitoring = lazy(() => import('./pages/monitoring'));
const JourneyMap = lazy(() => import('./pages/journeyMap'));

export const MonitoringRoutes: RouteObject[] = [
  {
    path: links.nexpro.monitoring,
    element: <Monitoring />,
  },
];
export const JourneyRoutes: RouteObject[] = [
  {
    path: links.nexpro.journey,
    element: <JourneyMap />,
  },
];
