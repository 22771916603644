import * as Collapsible from '@radix-ui/react-collapsible';
import { VariantProps } from 'class-variance-authority';
import { ChevronLeft, ChevronRight, Menu } from 'lucide-react';
import { useEffect, useState } from 'react';
import { create } from 'zustand';

import NexproTinyLogoImg from '@/assets/icons/logo.svg';
import NexproLogoImg from '@/assets/icons/nexpro2.jpg';
import { useAppStore } from '@/common/hooks/AppStore';

import { Config } from './components/Config';
import { NavItem } from './components/NavItem';
import * as style from './styles';

interface ISidebarStore {
  collapsed: boolean;
  toggleSidebar: () => void;
  openSidebar: () => void;
  closeSidebar: () => void;
}

export const useCollapseSidebar = create<ISidebarStore>(set => ({
  collapsed: false,
  toggleSidebar: () => set(state => ({ collapsed: !state.collapsed })),
  openSidebar: () => set({ collapsed: false }),
  closeSidebar: () => set({ collapsed: true }),
}));

type ContainerProps = VariantProps<typeof style.container>;

export function Sidebar() {
  const [containerBottom, setContainerBottom] =
    useState<ContainerProps['containerBottom']>('bottom-auto');
  const [hideMenu, setHideMenu] = useState(true);
  const { collapsed, toggleSidebar } = useCollapseSidebar();
  const { appNavigation } = useAppStore();

  const handleToggle = () => {
    toggleSidebar();
  };

  const handleToggleContainerStyle = () => {
    setContainerBottom(state =>
      state === 'bottom-auto' ? 'bottom-0' : 'bottom-auto',
    );
  };

  useEffect(() => {
    if (hideMenu) {
      setContainerBottom('bottom-auto');
    }
  }, [hideMenu]);

  return (
    <Collapsible.Root
      className={style.container({ containerBottom })}
      data-collapsed={collapsed}
    >
      <button
        type="button"
        className={style.collapseButton()}
        onClick={handleToggle}
        aria-label="Toggle Sidebar"
      >
        <ChevronLeft size={14} className="group-data-[collapsed=true]:hidden" />
        <ChevronRight
          size={14}
          className="hidden group-data-[collapsed=true]:block"
        />
      </button>

      <section className={style.header()}>
        <img
          src={NexproLogoImg}
          alt="Iveco"
          className={`${style.logo()} group-data-[collapsed=true]:hidden`}
        />
        <img
          src={NexproTinyLogoImg}
          alt="Iveco"
          className={`${style.tinyLogo()} hidden group-data-[collapsed=true]:block`}
        />

        <Collapsible.Trigger className="md:hidden">
          <button
            id="toggleButton"
            aria-label="Toggle Button"
            type="button"
            className="bg-brand-pure p-1 text-white rounded-sm"
            data-hs-collapse="#hs-basic-collapse-heading"
            onClick={() => {
              handleToggleContainerStyle();
              setHideMenu(state => !state);
            }}
          >
            <Menu size={20} />
          </button>
        </Collapsible.Trigger>
      </section>
      <Collapsible.Content
        forceMount
        className={`md:flex flex-col mt-8 h-full flex ${
          hideMenu ? 'hidden' : ''
        }`}
      >
        <nav className={style.menu()}>
          {appNavigation.map(menuItem => (
            <NavItem
              data={menuItem}
              key={menuItem.key}
              setHideMenu={setHideMenu}
            />
          ))}
        </nav>
        <div className="overflow-hidden">
          {' '}
          <Config setHideMenu={setHideMenu} />
        </div>
      </Collapsible.Content>
    </Collapsible.Root>
  );
}
