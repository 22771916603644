export enum UserRoleEnum {
  ROOT = 'ROOT',
  OWNER = 'OWNER',
  ADMIN = 'ADMIN',
  MANAGER = 'MANAGER',
  SUPPORT = 'SUPPORT',
  OPERATOR = 'OPERATOR',
  DRIVER = 'DRIVER',
  VIEWER = 'VIEWER',
}
