import { create } from 'zustand';

import { IDriverUser } from '../types/Driver';
import { IOwnerUser } from '../types/OwnerUser';
import { UserRoleEnum } from '../types/Role';
import {
  IGetUserInfo,
  IUserInfoPerson,
  IUserInfoUserAccount,
  IUserInfoUserCompany,
} from '../types/User';

type StoreAccountUserProps = {
  user: IGetUserInfo;
  account: IUserInfoUserAccount;
};

type StoreCompanyUserProps = {
  user: IGetUserInfo;
  company: IUserInfoUserCompany;
  driver: IDriverUser | null;
};

interface IUserStore {
  user: IGetUserInfo | null;
  person: IUserInfoPerson | null;
  personId: string | null;
  account: IUserInfoUserAccount | null;
  accountId: string | null;
  company: IUserInfoUserCompany | null;
  companyId: string | null;
  ownerId: string | null;
  owner: IOwnerUser | null;
  role: UserRoleEnum | null;
  companies: IUserInfoUserCompany[] | null;
  driver: IDriverUser | null;
  storeAccountUser: (data: StoreAccountUserProps) => void;
  storeCompanyUser: (data: StoreCompanyUserProps) => void;
}

export const useUserStore = create<IUserStore>(set => {
  const storeAccountUser = (data: StoreAccountUserProps) => {
    const { user, account } = data;

    set({
      user,
      person: user?.Person,
      personId: user?.personId,
      role: account.role,
      account,
      accountId: account.accountId,
      company: null,
      companyId: null,
      driver: null,
    });
  };

  const storeCompanyUser = (data: StoreCompanyUserProps) => {
    const { user, company, driver } = data;

    set({
      user,
      person: user?.Person,
      personId: user?.personId,
      role: company.role,
      company,
      companyId: company.companyId,
      ownerId: company.Company.ownerId,
      owner: company.Company.User,
      accountId: company.accountId,
      account: null,
      driver,
    });
  };

  return {
    user: null,
    person: null,
    personId: null,
    role: null,
    account: null,
    accountId: null,
    company: null,
    companyId: null,
    ownerId: null,
    owner: null,
    companies: null,
    driver: null,
    storeAccountUser,
    storeCompanyUser,
  };
});
