import { ChevronRight } from 'lucide-react';
import { Link } from 'react-router-dom';

import * as style from './styles';

export interface IBreadcrumb {
  title: string;
  link?: string;
}

type BreadcrumbProps = {
  isLink: boolean;
  isActive: boolean;
  isLast: boolean;
  data: IBreadcrumb;
};

export function Breadcrumb({
  isLink = false,
  isActive,
  isLast,
  data,
}: BreadcrumbProps) {
  return (
    <div className={style.breadcrumbWrapper()}>
      {isLink && (
        <Link
          to={data?.link ? data?.link : '#'}
          className={style.breadcrumb({
            isLink: true,
          })}
        >
          {data?.title}
        </Link>
      )}

      {!isLink && (
        <h2
          className={style.breadcrumb({
            active: isActive,
          })}
        >
          {data?.title}
        </h2>
      )}

      {!isLast && <ChevronRight size={20} className="text-gray-400" />}
    </div>
  );
}
