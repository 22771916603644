import { cva } from 'class-variance-authority';

export const container = cva(
  [
    'w-60',
    'rounded-lg',
    'border',
    'shadow-sm',
    'hover:border-brand-pure',
    'hover:shadow-md',
    'cursor-pointer',
    'relative',
    'w-full',
    'max-w-sm',
  ],
  {
    variants: {
      selected: {
        true: [
          'border-2',
          'border-brand-pure/80',
          'shadow-md',
          'bg-brand-pure/10',
        ],
        false: ['border-neutral-200', 'shadow-sm'],
      },
    },
  },
);

export const logo = cva(['w-full', 'object-contain', 'rounded-t-lg']);

export const info = cva(['py-2', 'px-4', 'relative', 'text-left']);

export const title = cva([
  'text-neutral-800',
  'text-base',
  'font-normal',
  'max-w-[90%]',
  'overflow-hidden overflow-ellipsis whitespace-pre',
]);

export const description = cva(['text-neutral-700', 'text-xs', 'font-light']);

export const iconContainer = cva([
  'absolute',
  'top-2',
  'right-4',
  'bg-brand-gradient',
  'rounded-lg',
  'p-1',
]);

export const icon = cva(['text-white']);
