import { cva } from 'class-variance-authority';

export const container = cva([
  'w-full',
  'sm:pb-5',
  'pb-1',
  'flex',
  'flex-col',
  'items-start',
]);

export const heading = cva(['flex', 'gap-1', 'w-full', 'justify-between']);

export const title = cva([
  'text-2xl',
  'md:text-3xl',
  'font-semibold',
  'text-brand-800',
]);

export const headerFooter = cva([
  'flex',
  'flex-col',
  'md:flex-row',
  'gap-5',
  'items-start',
  'md:items-center',
  'justify-between',
  'w-full',
  'mt-2',
]);

export const breadCrumbs = cva([
  'flex',
  'gap-5',
  'items-center',
  'hidden',
  'md:flex',
]);

export const actions = cva(['flex', 'justify-center', 'items-center', 'gap-4']);

export const actionsWrapper = cva(['flex', 'gap-4', 'items-end'], {
  variants: {
    hasDate: {
      true: ['w-1/2 xl:w-1/4'],
    },
  },
});

export const badgesContainer = cva(['grid', 'grid-cols-2', 'md:flex', 'gap-4']);
