import { cva } from 'class-variance-authority';

export const container = cva([
  'flex',
  'w-full',
  'min-h-screen',
  'items-center',
  'bg-no-repeat',
  'bg-[url("/src/assets/images/truck-right-background.png")]',
  'bg-center',
  'md:bg-right',
  'bg-cover',
]);

export const content = cva(['pl-20', 'h-full']);

export const main = cva([
  'flex',
  'flex-col',
  'gap-6',
  '2xl:gap-10',
  'items-center',
  'md:items-start',
  'max-w-lg',
]);

export const status = cva([
  'font-inter',
  'font-semibold',
  'text-5xl',
  'xl:text-8xl',
  'text-neutral-800',
]);
export const textWrapper = cva(['max-w-md', 'my-10']);

export const textError = cva([
  'font-inter',
  'font-light',
  'text-xl',
  'xl:text-3xl',
  'text-neutral-700',
]);

export const buttons = cva(['flex', 'w-full', 'justify-center', 'gap-4']);

export const wrapperButton = cva([
  'w-full',
  'flex',
  'items-center',
  'justify-center',
  'mb-4',
]);

export const homeIcon = cva(['text-white']);

export const tryAgainText = cva([
  'text-brand-pure',
  'font-inter',
  'font-bold',
  'text-xl',
  'xl:text-3xl',
]);
