import { cva } from 'class-variance-authority';

export const Container = cva(
  [
    'inline-flex',
    'items-center',
    'justify-center',
    'gap-1.5',
    'py-1.5',
    'pl-3',
    'pr-3',
    'rounded-md',
    'text-xs',
    'font-medium',
  ],
  {
    variants: {
      type: {
        info: ['bg-brand-100', 'text-brand-pure'],
        danger: ['bg-driver-centricity-100', 'text-driver-centricity-800'],
        alternativeDanger: ['bg-driver-centricity-800', 'text-neutral-800'],
        success: [
          'bg-sustainability-green-100',
          'text-sustainability-green-900',
        ],
        alternativeSuccess: ['bg-sustainability-green-800', 'text-neutral-800'],
        warning: ['bg-alert-100', 'text-alert-800'],
        alert: ['bg-alert-800', 'text-neutral-800'],
        gray: ['bg-neutral-100', 'text-neutral-800'],
      },
    },
  },
);

export const RemoveButton = cva(
  [
    'flex-shrink-0',
    'h-4',
    'w-4',
    'inline-flex',
    'items-center',
    'justify-center',
    'rounded-full',

    'focus:outline-none',
  ],
  {
    variants: {
      type: {
        info: [
          'text-blue-600',
          'hover:bg-blue-200',
          'hover:text-blue-500',
          'focus:bg-blue-200',
          'focus:text-blue-500',
        ],
        danger: [
          'text-red-600',
          'hover:bg-red-200',
          'hover:text-red-500',
          'focus:bg-red-200',
          'focus:text-red-500',
        ],
        alternativeDanger: [
          'text-red-600',
          'hover:bg-red-200',
          'hover:text-red-500',
          'focus:bg-red-200',
          'focus:text-red-500',
        ],
        success: [
          'text-sustainability-green-600',
          'hover:bg-sustainability-green-200',
          'hover:text-sustainability-green-500',
          'focus:bg-sustainability-green-200',
          'focus:text-sustainability-green-500',
        ],
        alternativeSuccess: [
          'text-sustainability-green-600',
          'hover:bg-sustainability-green-200',
          'hover:text-sustainability-green-500',
          'focus:bg-sustainability-green-200',
          'focus:text-sustainability-green-500',
        ],
        warning: [
          'text-alert-600',
          'hover:bg-alert-200',
          'hover:text-alert-500',
          'focus:bg-alert-200',
          'focus:text-alert-500',
        ],
        alert: [
          'text-alert-600',
          'hover:bg-alert-200',
          'hover:text-alert-500',
          'focus:bg-alert-200',
          'focus:text-alert-500',
        ],
        gray: [
          'text-neutral-600',
          'hover:bg-neutral-200',
          'hover:text-neutral-500',
          'focus:bg-neutral-200',
          'focus:text-neutral-500',
        ],
      },
    },
  },
);

export const RemoveIcon = cva(['h-4', 'w-4'], {
  variants: {
    type: {
      info: ['text-brand-pure'],
      danger: ['text-driver-centricity-800'],
      alternativeDanger: ['text-driver-centricity-800'],
      success: ['text-sustainability-green-800'],
      alternativeSuccess: ['text-sustainability-green-800'],
      warning: ['text-alert-800'],
      alert: ['text-neutral-800'],
      gray: ['text-neutral-800'],
    },
  },
});
