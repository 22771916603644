import { ArrowLeft, LayoutGrid, Info } from 'lucide-react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';

import { CardApp } from '@/common/components/CardApp';
import { EnumAPPS, useAppStore } from '@/common/hooks/AppStore';
import { useUserStore } from '@/common/hooks/UserStore';
import { IGetUserInfo, IUserInfoUserCompany } from '@/common/types/User';
import { setItemLocal } from '@/common/utils/localStorage';

import { useLogin } from '../../hooks/useLogin';

import * as style from './styles';

export default function AppSelection() {
  const { state } = useLocation();

  const user = state.user as IGetUserInfo;
  const companies = user.UserCompany;
  const { t } = useTranslation(['login/appSelection', 'common']);

  const { signOut } = useLogin();

  const { changeApp } = useAppStore();
  const navigate = useNavigate();
  const { storeCompanyUser } = useUserStore();

  const handleLoadApp = (selectedCompany: IUserInfoUserCompany) => {
    if (selectedCompany?.companyId) {
      const driver =
        user?.Driver && user?.Driver?.length > 0 ? user?.Driver[0] : null;

      setItemLocal('companyId', selectedCompany?.companyId);
      storeCompanyUser({ user, company: selectedCompany, driver });
    }

    // * Only one front-end APP exists for now
    setItemLocal('app', EnumAPPS.NEXPRO);
    changeApp(EnumAPPS.NEXPRO);
    navigate('/');
  };

  return (
    <main className={style.container()}>
      <header className={style.header()}>
        <div className={style.titleContainer()}>
          <h1 className={style.title()}>{t('systemAccess')}</h1>

          <LayoutGrid className={style.icon()} />
        </div>

        <p className={style.subTitle()}>
          {t('selectCompany')}{' '}
          <strong className={style.nexpro()}>Nexpro</strong>.
        </p>
      </header>

      {companies && companies?.length > 0 && (
        <section className={style.section()}>
          <div className={style.sectionInfo()}>
            <h2 className={style.sectionTitle()}>{t('companies')}</h2>
            <p className={style.sectionSubTitle()}>{t('availableCompanies')}</p>
          </div>

          <ul className={style.list()}>
            {companies
              .filter(cmp => cmp.Company.CompanyModule.length)
              .map(company => (
                <li>
                  <CardApp
                    title={company.Company.displayName}
                    description={company.Company.documentNumber}
                    onClick={() => handleLoadApp(company)}
                  />
                </li>
              ))}
          </ul>

          <div className={style.tipContainer()}>
            <Info className={style.icon()} />
            <span className={style.tip()}>{t('companyChangeTip')}</span>
          </div>
        </section>
      )}

      <footer className={style.footer()}>
        <div className={style.link()}>
          <ArrowLeft size={16} />
          <button type="button" onClick={signOut}>
            {t('backToLogin')}
          </button>
        </div>
      </footer>
    </main>
  );
}
