import { lazy } from 'react';
import { RouteObject } from 'react-router-dom';

import { links } from '@/common/utils/links';

const CheckInPage = lazy(() => import('./pages/checkIn'));

export const CheckInRoutes: RouteObject[] = [
  {
    path: links.nexpro.checkIn,
    element: <CheckInPage />,
  },
];
