import { useQueryClient } from '@tanstack/react-query';
import { LayoutGrid } from 'lucide-react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { useGetDashboardData } from '@/apps/nexpro/modules/dashboard/api/useGetDashboardData';
import { useMapCoordinates } from '@/apps/nexpro/modules/dashboard/api/useMapCoordinates';
import { getDashboardDataService } from '@/apps/nexpro/modules/dashboard/services/getDashboardData';
import { getMapCoordinates } from '@/apps/nexpro/modules/dashboard/services/getMapCoordinates';
import { CardApp } from '@/common/components/CardApp';
import { Modal } from '@/common/components/Modal';
import { EnumAPPS, useAppStore } from '@/common/hooks/AppStore';
import { useUserStore } from '@/common/hooks/UserStore';
import { useToast } from '@/common/hooks/useToast';
import { IUserInfoUserCompany } from '@/common/types/User';
import { setItemLocal } from '@/common/utils/localStorage';

import * as style from './styles';

export function AppSelectionModal() {
  const {
    user,
    storeCompanyUser,
    companyId: currentCompanyId,
  } = useUserStore();

  const { t } = useTranslation(['login/appSelection', 'common']);
  const { toast } = useToast();

  const handleLoadApp = async (selectedCompany: IUserInfoUserCompany) => {
    if (!user) {
      toast.error(t('login/signIn:loadNexproModuleError'));
      return;
    }
    if (selectedCompany?.companyId) {
      const driver =
        user?.Driver && user?.Driver?.length > 0 ? user?.Driver[0] : null;
      setItemLocal('companyId', selectedCompany?.companyId);
      storeCompanyUser({ user, company: selectedCompany, driver });
    }
    window.HSOverlay.close(document.getElementById('hs-system-access-modal')!);
    toast.success(t('common:success'), t('storeChanged'));
  };

  return (
    <Modal
      id="system-access-modal"
      title={t('changeCompanyAccess')}
      titleIcon={<LayoutGrid size={24} className={style.icon()} />}
      dispensable
      subTitle={
        <p className={style.subTitle()}>
          {t('selectOtherCompany')}{' '}
          <strong className={style.nexpro()}>Nexpro</strong>.
        </p>
      }
    >
      <div className={style.content()}>
        <section className={style.section()}>
          <ul className={style.list()}>
            {user?.UserCompany?.filter(
              cmp => cmp.Company.CompanyModule.length,
            ).map(cmp => (
              <li key={cmp.companyId}>
                <CardApp
                  currentCompanyId={currentCompanyId!}
                  title={cmp.Company.displayName}
                  companyId={cmp.companyId}
                  description={cmp.Company.documentNumber}
                  onClick={() => handleLoadApp(cmp)}
                />
              </li>
            ))}
          </ul>
        </section>
      </div>
    </Modal>
  );
}
