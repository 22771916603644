import { lazy } from 'react';
import { RouteObject } from 'react-router-dom';

import { links } from '@/common/utils/links';

const GeoFences = lazy(() => import('./pages/geofences'));

export const GeoFencesRoutes: RouteObject[] = [
  {
    path: links.nexpro.geoFences,
    element: <GeoFences />,
  },
];
