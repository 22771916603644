import { Link, useLocation } from 'react-router-dom';

import { useUserProfileMenus } from '@/common/hooks/useUserProfileMenus';

import * as style from './styles';

export function Sidebar() {
  const location = useLocation();

  const menus = useUserProfileMenus();

  return (
    <nav className={style.container()}>
      <ul className={style.wrapperLinks()}>
        {menus?.map(menu => {
          return (
            <li key={menu?.key} className={style.navItem()}>
              <Link
                to={menu?.link}
                className={style.link({
                  active: location.pathname.includes(menu?.link),
                })}
              >
                <span
                  className={style.text({
                    active: location.pathname.includes(menu?.link),
                  })}
                >
                  {menu?.title}
                </span>
              </Link>
            </li>
          );
        })}
      </ul>
    </nav>
  );
}
